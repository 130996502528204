<template>
  <div class="tab-header">
    <div class="role-btn">
      <b-button
        variant="primary"
        v-if="this.$route.name !== 'view-role'"
        :class="!isSaveShow ? 'add-user-btn' : 'clone-role-btn'"
        size="sm"
        @click="redirectAddScreen()"
      >
        <!-- <feather-icon icon="SaveIcon" size="16" class="mr-0 mr-sm-50" /> -->

        <CloneRole class="clone-size" />
        <span class="d-none d-sm-inline CloneRole">{{
          $t("roles_management.CloneRole")
        }}</span>
      </b-button>
      <b-button
        variant="primary ml-1"
        class="add-user-btn"
        size="sm"
        @click="updateRole"
        v-if="
          (isSaveShow && this.selectedTab === 1 && this.$route.name !== 'view-role' ) ||
          this.$route.name !== 'view-role'
        "
      >
        <feather-icon icon="SaveIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("role.Save") }}</span>
      </b-button>
      <b-button
        variant="outline-primary ml-1"
        :class="!isSaveShow ? 'back-btn-clone v2-back' : 'back-btn v2-back'"
        size="sm"
        @click="redirectBackScreen"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
      </b-button>
    </div>

    <b-tabs pills v-model="selectedTab">
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("role.Users") }}</span>
        </template>

        <b-card class="mt-36 edit-role-owner">
          <h4 class="card-title">
            {{
              $route.name !== "view-role"
                ? $t("role.Editrole", {
                    roleName:
                      roleData && roleData.role ? roleData.role.role_name : ""
                  })
                : $t("role.Viewrole", {
                    roleName:
                      roleData && roleData.role ? roleData.role.role_name : ""
                  })
            }}
            <b-button
              v-if="!selectedTab && this.$route.name !== 'view-role'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right"
              size="sm"
              v-b-modal.modal-add-users
            >
              <feather-icon icon="PlusIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">{{ $t("role.AddUser") }}</span>
            </b-button>
          </h4>
          <RolesList
            :users="roleData.users"
            :hasNext="roleData.has_next"
            :count="roleData.count"
            :getRoleData="getRoleData"
            :roleName="roleData && roleData.role && roleData.role.role_name"
          />
        </b-card>
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="CheckCircleIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("role.Permissions") }}</span>
        </template>
        <b-card class="mt-36">
          <h4 class="card-title">{{ $t("role.Rolepermissions") }}</h4>
          <div class="card-divider"></div>
          <div>
            <Permissions
              :roleData="roleData.role"
              :allPermissions="roleData.permissions"
              :labels="roleData.labels"
            />
          </div>
        </b-card>
      </b-tab>
    </b-tabs>

    <AddUser
      :roleName="roleData && roleData.role && roleData.role.role_name"
      :pId="roleData && roleData.role && roleData.role.pid"
      :roleId="roleData && roleData.role && roleData.role.id"
      :updateUsers="updateUsers"
      v-if="isSelectedUserList"
      :selectedUserList="selectedUserList"
    />
    <b-overlay :show="show" no-wrap> </b-overlay>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BOverlay,
  BImg
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RolesList from "@/layouts/components/RolesManagement/RolesList.vue";
import Permissions from "@/layouts/components/RolesManagement/Permissions.vue";
import AddUser from "@/layouts/components/RolesManagement/AddUser.vue";
import AccountService from "@/libs/api/account-service";
import constants from "@/utils/constants";
import CloneRole from "@/assets/images/new-icon/CloneRole.svg";
export default {
  directives: {
    Ripple
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RolesList,
    Permissions,
    AddUser,
    BButton,
    BOverlay,
    BImg,
    CloneRole
  },
  data() {
    return {
      roleData: {},
      show: false,
      selectedTab: 0,
      isSaveShow: false,
      isSelectedUserList: false,
      selectedUserList: []
    };
  },
  methods: {
    async updateRole() {
      const me = this;
      me.show = true;
      try {
        const upData = {
          role_id: me.$route.query.id || me.$route.params.id || null,
          pid: me.roleData && me.roleData.role && me.roleData.role.pid,
          permissions: me.parseRoles()
        };
        if (me.roleData.users.length) {
          upData.users = me.roleData.users.map((x) => x.id);
        }
        let response = await new AccountService().updateRole(upData);
        me.show = false;
        if (response && response.data) {
          me.getRoleData();
          me.$toast({
            component: ToastificationContent,
            props: {
              title: me.$t("role.RoleUpdateSuccess"),
              text: me.$t("role.RoleUpdateSuccessMessage"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          me.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        me.show = false;
      }
    },
    updateUsers(invitedUsers) {
      this.roleData.users = invitedUsers;
      this.updateRole();
    },
    redirectAddScreen(id) {
      this.$router.push({
        name: "create-role",
        query: { id: this.$route.params.id }
      });
    },
    parseRoles() {
      let rolePerms = {};
      let allPermissions = this.roleData.permissions;
      Object.keys(allPermissions).forEach((permKey) => {
        Object.keys(allPermissions[permKey].op).forEach((action) => {
          if (allPermissions[permKey].op[action].per) {
            if (!rolePerms[permKey]) {
              rolePerms[permKey] = [];
            }
            rolePerms[permKey].push(action);
          }
        });
      });
      return rolePerms;
    },
    processRolePermissions() {
      let rolePerms = this.roleData.role.permissions;
      let allPermissions = this.roleData.permissions;
      Object.keys(rolePerms).forEach((permKey) => {
        rolePerms[permKey].forEach((action) => {
          if (allPermissions[permKey]) {
            try {
              allPermissions[permKey].op[action].per = true;
            } catch (err) {}
          }
        });
      });
    },
    redirectBackScreen(id) {
      this.$router.push({ name: "user-list", query: { tab: "roles" } });
    },
    async getRoleData() {
      const me = this;
      const id = me.$route.query.id || me.$route.params.id || null;
      if (!id) {
        return;
      }
      let response = await new AccountService().getRoleData({
        role_id: id
      });
      this.$route.meta.breadcrumb = JSON.parse(
        JSON.stringify(constants.ROLE_CREATE_ROUTE_META_BREADCRUMB)
      );
      if (response && response.data) {
        me.roleData = response.data;
        const roleName =
          me.roleData &&
          me.roleData.role &&
          me.roleData.role &&
          me.roleData.role.role_name;
        if (roleName) {
          this.$route.meta.breadcrumb.push(
            {
              text: roleName,
              index: roleName,
              active: true
            },
            {
              text: this.$route.name === "edit-role" ? "Edit" : "",
              index: "edit",
              active: true
            }
          );
        }

        me.selectedUserList = response.data.users;
        me.pid = me.roleData && me.roleData.role && me.roleData.role.pid;
        me.isSelectedUserList = true;
        me.isSaveShow =
          !me.roleData || !me.roleData.role || !me.roleData.role.is_default
            ? true
            : false;
        me.processRolePermissions();
      } else if (response && response.error && response.error.message) {
        me.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  },
  async mounted() {
    await this.getRoleData();
  }
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.edit-role-owner {
  .card-title {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.back-btn-clone {
  margin-left: auto;
  margin-right: $px_15;
}
.clone-role-btn {
  margin-left: auto;
}
.fill-white svg,
.fill-white path {
  fill: var(--white);
}
</style>
<style lang="scss">
.tab-header {
  .back-btn {
    margin-bottom: 0px !important;
  }
}
</style>
