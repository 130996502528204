var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permissions-list"},[(_vm.allPermissions)?_c('app-collapse',{staticClass:"mb-1",attrs:{"accordion":""}},_vm._l((Object.keys(_vm.allPermissions)),function(permissionKey,index){return _c('app-collapse-item',{key:index,staticClass:"accordion-card",attrs:{"title":_vm.allPermissions[permissionKey].label}},[(_vm.allPermissions[permissionKey].op)?_c('div',{staticClass:"mobile-manage-list d-flex p-1 flex-wrap"},_vm._l((Object.keys(
            _vm.allPermissions[permissionKey].op
          )),function(operationKey,idx){return _c('div',{key:idx,staticClass:"check-list"},[_c('div',{on:{"click":function($event){return _vm.handleClick(_vm.allPermissions[permissionKey], operationKey)}}},[_c('b-form-checkbox',{attrs:{"title":_vm.getLabel(
                  operationKey,
                  _vm.allPermissions[permissionKey].op[operationKey]
                ),"disabled":!_vm.allPermissions[permissionKey].op[operationKey].allowed ||
                _vm.roleData.is_default ||
                _vm.$route.name === 'view-role' ||
                (_vm.allPermissions[permissionKey].op[operationKey]
                  .disabled_for &&
                  _vm.allPermissions[permissionKey].op[
                    operationKey
                  ].disabled_for.includes(_vm.currentAccType))},on:{"change":function (e) { return _vm.autoSelectParent(
                    e,
                    _vm.allPermissions[permissionKey].op[operationKey],
                    _vm.allPermissions[permissionKey].op,
                    operationKey,
                    _vm.allPermissions,
                    _vm.allPermissions[permissionKey],
                    permissionKey
                  ); }},model:{value:(_vm.allPermissions[permissionKey].op[operationKey].per),callback:function ($$v) {_vm.$set(_vm.allPermissions[permissionKey].op[operationKey], "per", $$v)},expression:"allPermissions[permissionKey].op[operationKey].per"}},[_vm._v(" "+_vm._s(_vm.getLabel( operationKey, _vm.allPermissions[permissionKey].op[operationKey] ))+" ")])],1)])}),0):_vm._e()])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }