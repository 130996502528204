<template>
  <b-modal
    id="modal-add-users"
    centered
    no-close-on-esc
    no-close-on-backdrop
    modal-class="no-header-modal"
    :hide-header="true"
  >
    <div style="padding: 0.8rem 1.4rem"></div>
    <b-card-text>
      <div class="new-invite-title">{{ $t("role.AddUser") }}</div>
      <div class="you-invite">{{ $t("user.AddUsers") }}</div>
      <b-form class="mtt-37">
        <div class="add-email">
          <b-form-group :label="$t('role.Users')" class="mb-0">
            <v-select
              id="vue-select"
              :placeholder="$t('role.SelectUsers')"
              v-model="selectedUser"
              :options="allUsers"
              :reduce="(option) => option.id"
              label="title"
            />
          </b-form-group>
          <b-button
            variant="primary"
            size="sm"
            class="btn-icon rounded-circle mb-0"
            @click="addSelectedUser"
          >
            <feather-icon icon="PlusIcon" size="18" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="11">
            <div class="mt-1">{{ $t("role.AddUserInfo") }}</div>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>

        <div
          class="media-list media-bordered btm"
          :key="index"
          v-for="(user, index) of invitedUsers"
        >
          <b-media right-align vertical-align="center">
            <template #aside>
              <b-button
                variant="danger"
                size="sm"
                class="btn-icon rounded-circle"
                @click="removeSelectedUser(index, user)"
              >
                <feather-icon icon="TrashIcon" size="18" /> </b-button
            ></template>
            <b-media class="align-item-center">
              <template #aside>
                <b-avatar
                  size="50"
                  :text="getInitials(user.title)"
                  variant="light-primary"
                />
              </template>
              <h6 class="mb-0 user-mt-15">{{ user.title }}</h6>
            </b-media>
          </b-media>
        </div>
      </b-form>
      <div class="d-flex justify-content-center mb-64 mtt-50">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          :disabled="!invitedUsers || !invitedUsers.length"
          type="reset"
          class="mt-2 mr-10-1"
          @click="save"
        >
          {{ $t("user.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-primary"
          type="reset"
          class="mt-2"
          @click="close"
        >
          {{ $t("user.Cancel") }}
        </b-button>
      </div>
    </b-card-text>

    <Loader :show="show" />
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardText,
  BFormGroup,
  BModal,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccountService from "@/libs/api/account-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BMedia,
    Loader,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BCardText,
    BModal,
    BForm
  },
  directives: {
    Ripple
  },
  props: {
    updateUsers: {
      type: Function
    },
    roleName: {
      type: String
    },
    pId: {
      type: String
    },
    roleId: {
      type: String
    },
    selectedUserList: {
      type: Array
    }
  },
  data() {
    return {
      selectedUser: null,
      allUsers: [],
      allUsersClone: [],
      invitedUsers: [],
      show: false
    };
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      try {
        let response = await new AccountService().getAccountUsers({});
        if (response && response.data) {
          this.allUsers = response.data.users.map((u) => {
            return {
              id: u.id,
              title: u.first_name + " " + u.last_name,
              value: u.id
            };
          });

          const selectedUserIds = this.selectedUserList.map((u) => u.id);
          this.allUsers = this.allUsers.filter(
            (u) => !selectedUserIds.includes(u.id)
          );
          let loggedUser = localStorage.getItem("USER_PROFILE_DETAILS")
            ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS")) || {}
            : {};
          if (
            loggedUser &&
            loggedUser.id &&
            this.allUsers &&
            this.allUsers.length
          ) {
            this.allUsers = this.allUsers.filter(
              (x) => x && x.id !== loggedUser.id
            );
          }
          this.allUsersClone = JSON.parse(JSON.stringify(this.allUsers));
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    removeSelectedUser(index, user) {
      this.invitedUsers.splice(index, 1);
      const selectedUser = this.allUsersClone.filter(
        (x) => x && x.id === user.id
      );
      if (selectedUser && selectedUser.length) {
        this.allUsers.push(selectedUser[0]);
      }
    },
    addSelectedUser() {
      const selectedUserObj = this.allUsers.filter(
        (x) => x.id === this.selectedUser
      );
      const invitedUserObj = this.invitedUsers.filter(
        (x) => x.id === this.selectedUser
      );
      if (invitedUserObj.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("role.UserAlreadyAddedTitle"),
            text: this.$t("role.UserAlreadyAddedMessage"),
            icon: "InfoIcon",
            variant: "success"
          }
        });
        return;
      }
      if (selectedUserObj && selectedUserObj.length) {
        this.invitedUsers.push(selectedUserObj[0]);
        this.allUsers = this.allUsers.filter(
          (x) => x && x.id !== selectedUserObj[0].id
        );
      }
      this.selectedUser = null;
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((x) => x.substring(0, 1))
        .join("");
    },
    save() {
      if (this.invitedUsers.length) {
        this.updateUsers(this.invitedUsers);
      }
      this.$bvModal.hide("modal-add-users");
    },
    close() {
      this.invitedUsers = [];
      // this.updateUsers([]);
      this.selectedUser = null;
      this.allUsers = this.allUsersClone;
      this.$bvModal.hide("modal-add-users");
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-top: 32px;
}

.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.add-email {
  display: flex;
  align-items: flex-end;
  .form-group {
     width: calc(100% - 37px);
  }
  .rounded-circle.btn-primary {
    margin-bottom: 18px;
    margin-left: $px_10;
  }
}
.rounded-circle {
  width: 43px;
  height: 36px;
  padding: 0 !important;
}
.dark-layout {
  .new-invite-title {
    color: var(--white);
  }
}
.user-mt-15 {
  margin-top: 15px;
}
</style>
