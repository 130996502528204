<template>
  <div class="permissions-list">
    <app-collapse accordion v-if="allPermissions" class="mb-1">
      <app-collapse-item
        class="accordion-card"
        :key="index"
        v-for="(permissionKey, index) of Object.keys(allPermissions)"
        :title="allPermissions[permissionKey].label"
      >
        <div
          v-if="allPermissions[permissionKey].op"
          class="mobile-manage-list d-flex p-1 flex-wrap"
        >
          <div
            class="check-list"
            :key="idx"
            v-for="(operationKey, idx) of Object.keys(
              allPermissions[permissionKey].op
            )"
          >
            <div
              @click="handleClick(allPermissions[permissionKey], operationKey)"
            >
              <b-form-checkbox
                v-model="allPermissions[permissionKey].op[operationKey].per"
                :title="
                  getLabel(
                    operationKey,
                    allPermissions[permissionKey].op[operationKey]
                  )
                "
                @change="
                  (e) =>
                    autoSelectParent(
                      e,
                      allPermissions[permissionKey].op[operationKey],
                      allPermissions[permissionKey].op,
                      operationKey,
                      allPermissions,
                      allPermissions[permissionKey],
                      permissionKey
                    )
                "
                :disabled="
                  !allPermissions[permissionKey].op[operationKey].allowed ||
                  roleData.is_default ||
                  $route.name === 'view-role' ||
                  (allPermissions[permissionKey].op[operationKey]
                    .disabled_for &&
                    allPermissions[permissionKey].op[
                      operationKey
                    ].disabled_for.includes(currentAccType))
                "
              >
                {{
                  getLabel(
                    operationKey,
                    allPermissions[permissionKey].op[operationKey]
                  )
                }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BForm, BFormCheckbox } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox
  },
  props: {
    roleData: {
      type: Object,
      default: () => {}
    },
    allPermissions: {
      type: Object,
      default: () => {}
    },
    labels: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentAccType: localStorage.getItem("USER_ACCOUNT_TYPE")
    };
  },
  methods: {
    handleClick(permissionKey, operationKey) {
      if (!permissionKey.op[operationKey].allowed) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Permissions.Error.NotAllowed"),
            // text: this.$t("Permissions.Error.NotAllowed"),
            icon: "EditIcon",
            variant: "warning"
          }
        });
      }
    },
    getLabel(action, opObj) {
      return opObj && opObj.label
        ? opObj.label
        : this.labels[action]
        ? this.labels[action]
        : "Unknown";
    },
    autoSelectParent(
      e,
      action,
      allOperations,
      operationKey, // "a"
      allPermissions,
      permissionKey,
      permissionKey1
    ) {
      if (!e && allOperations) {
        // console.log("permissionKey1", permissionKey1);
        if (!permissionKey.op[operationKey].allowed) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Permissions.Error.NotAllowed"),
              // text: this.$t("Permissions.Error.NotAllowed"),
              icon: "EditIcon",
              variant: "warning"
            }
          });
        } else {
          let br = 0;
          const getKeys = Object.keys(allOperations);
          for (let i = 0; i < getKeys.length; i++) {
            let key = getKeys[i];
            if (br > 0) {
              break;
            }
            if (
              key !== operationKey &&
              allOperations[key].per &&
              allOperations[key].parent &&
              allOperations[key].parent.length &&
              allOperations[key].parent.includes(operationKey)
            ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Permissions.Error.CanNotModify"),
                  icon: "EditIcon",
                  variant: "warning"
                }
              });
              br++;
              break;
            } else if (
              allOperations[key] &&
              allOperations[key].needed_for &&
              Object.keys(allOperations[key].needed_for) &&
              Object.keys(allOperations[key].needed_for).length
            ) {
              const needForKeys = Object.keys(allOperations[key].needed_for);
              for (let m = 0; m < needForKeys.length; m++) {
                if (br > 0) {
                  break;
                }
                let k = needForKeys[m];
                const getValues = Object.values(
                  allOperations[key].needed_for[k]
                )[0];
                for (let q = 0; q < getValues.length; q++) {
                  let p = getValues[q];
                  if (br > 0) {
                    break;
                  }

                  if (
                    allPermissions[
                      Object.keys(allOperations[key].needed_for[k])[0]
                    ].op[p].per &&
                    allPermissions[
                      Object.keys(allOperations[key].needed_for[k])[0]
                    ].op[p].dependant_on &&
                    allPermissions[
                      Object.keys(allOperations[key].needed_for[k])[0]
                    ].op[p].dependant_on.length
                  ) {
                    const dependant_on =
                      allPermissions[
                        Object.keys(allOperations[key].needed_for[k])[0]
                      ].op[p].dependant_on;

                    for (let c = 0; c < dependant_on.length; c++) {
                      if (br > 0) {
                        break;
                      }
                      let depkey = Object.keys(dependant_on[c])[0];
                      if (
                        depkey === permissionKey1 &&
                        dependant_on[c][depkey].includes(operationKey)
                      ) {
                        br++;
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.$t("Permissions.Error.CanNotModify"),
                            icon: "EditIcon",
                            variant: "warning"
                          }
                        });
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (e && action.parent.length) {
        action.parent.forEach((parent) => {
          allOperations[parent].per = e;
        });
      }

      if (e && action.parent.length) {
        action.parent.forEach((parent) => {
          allOperations[parent].per = e;
        });
      }

      const getKeys = Object.keys(allOperations);
      getKeys.forEach((key) => {
        // console.log(allOperations[key].per);
        if (allOperations[key].per === true) {
          allOperations[key].parent.forEach((parent) => {
            allOperations[parent].per = true;
          });

          if (
            allOperations[key] &&
            allOperations[key].dependant_on &&
            Object.keys(allOperations[key].dependant_on) &&
            Object.keys(allOperations[key].dependant_on).length
          ) {
            Object.keys(allOperations[key].dependant_on).forEach((m) => {
              Object.values(allOperations[key].dependant_on[m])[0].forEach(
                (p) => {
                  if (
                    allPermissions[
                      Object.keys(allOperations[key].dependant_on[m])[0]
                    ].op[p]
                  ) {
                    allPermissions[
                      Object.keys(allOperations[key].dependant_on[m])[0]
                    ].op[p].per = true;
                  }
                }
              );
            });
          }
        } else {
          if (
            allOperations[key] &&
            allOperations[key].needed_for &&
            Object.keys(allOperations[key].needed_for) &&
            Object.keys(allOperations[key].needed_for).length
          ) {
            Object.keys(allOperations[key].needed_for).forEach((m) => {
              Object.values(allOperations[key].needed_for[m])[0].forEach(
                (p) => {
                  if (
                    allPermissions[
                      Object.keys(allOperations[key].needed_for[m])[0]
                    ].op[p]
                  ) {
                    if (
                      allPermissions[
                        Object.keys(allOperations[key].needed_for[m])[0]
                      ].op[p].per
                    ) {
                      action.per = true;
                    }
                  }
                }
              );
            });
          }
        }
      });
    }
  }
};
</script>
<style lang="scss">
.accordion-card {

  .card-body {
    min-height: auto !important;

}

}
</style>
<style lang="scss" scoped>

.permissions-list {
  overflow-y: scroll;
  height: calc(100vh - 300px);
}
.dark-layout .accordion-card {
  background-color: #293046;
}

.dark-layout .accordion-card {
  border: 1px solid #3b4253;
}
</style>


