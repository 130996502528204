<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 full-size">
      <b-table
        class="position-relative no-headers user-roles-list"
        responsive
        show-empty
        align-v="end"
        :items="users"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.profile_pic_url"
                :text="getInitials(data.item)"
                variant="light-primary"
              />
            </template>
            <h6 class="mb-0">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </h6>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>
        <template #cell(action)="data">
          <div class="text-right" v-if="!isReadOnly">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                icon="Trash2Icon"
                v-if="isSelfUser(data.item)"
                class="text-danger action-icon"
                size="18"
                style="position: relative; margin-right: 10px; cursor: pointer"
                @click="removeUser(data.item)"
              />
            </span>
          </div>
          <b-tooltip delay="100" variant="primary" :target="data.item.id">{{
            $t("tooTip.delete")
          }}</b-tooltip>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="count > 0"
              v-model="currentPage"
              :total-rows="count"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      :data="{
        ...user,
        modelName: 'modal-sm-remove-user',
        first_name: roleName ? roleName : 'this',
        last_name: ''
      }"
      :onClose="onClose"
      :removedUser="removedUser"
      :customData="customData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Ripple from "vue-ripple-directive";
import AccountService from "@/libs/api/account-service";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AreYouSureModal,
    BTooltip,
    VBTooltip
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    hasNext: {
      type: Boolean,
      default: false,
      required: true
    },
    getRoleData: {
      type: Function
    },
    count: {
      type: Number,
      default: 0
    },
    roleName: {
      type: String
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (!page) {
        this.updateUrl({ key: "perPage", value: this.perPage });
      } else {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    this.isReadOnly = this.$route.name === "view-role" ? true : false;
  },
  data() {
    return {
      tableColumns: [
        { key: "name", label: this.$t("historyTableColumns.name") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      user: null,
      isReadOnly: false,
      selected: "10",
      option: ["10", "20", "30"],
      customData: {}
    };
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.getInitials();
    },
    currentPage(newPage) {
      this.updateUrl({ key: "perPage", value: this.currentPage });
      this.getInitials();
    },
    filter() {
      this.getInitials();
    }
  },

  methods: {
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    getInitials(user) {
      return (
        user.first_name &&
        user.first_name.substring(0, 1) +
          (user.last_name && user.last_name
            ? user.last_name.substring(0, 1)
            : "")
      );
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove-user");
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        perPage: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.getInitials();
    },
    async removedUser(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "User Deleted",
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        let response = await new AccountService().userRemoved({
          user_id: params.id
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "User Removed",
              text: "User removed successfully",
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.onClose();
          this.getRoleData();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    removeUser(user) {
      this.user = user;
      this.customData = {
        icon: "XIcon",
        msgTxt: this.$t("roles_management.ConfirmDeleteRoleUserMsgTxt"),
        isQuite: false,
        title: this.$t("roles_management.ConfirmDeleteRoleUserMsgTitle"),
        btnTxt: this.$t("roles_management.ConfirmDeleteRoleUserBtnTxt"),
        lastTxt: this.$t("roles_management.ConfirmDeleteRoleUserLastTxt")
      };
      this.$bvModal.show("modal-sm-remove-user");
    },
    isSelfUser(user) {
      const loggedUser = localStorage.getItem("USER_PROFILE_DETAILS")
        ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"))
        : {};
      if (user.id === loggedUser.id) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
